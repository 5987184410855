import { createContext, useState, useEffect, useContext } from "react";
import wretch from "wretch";
import { Storage } from "@capacitor/storage";
import { apiUrl } from "../api";
import honeybadger from "../honeybadger";

async function getSession() {
  const session = await Storage.get({ key: "professio-session" });
  const parsedSession = JSON.parse(session.value);
  return parsedSession;
}

async function setSession(session) {
  const stringify = JSON.stringify(session);
  await Storage.set({
    key: "professio-session",
    value: stringify,
  });
}

async function removeSession() {
  await Storage.remove({ key: "professio-session" });
}

const AuthContext = createContext();
const API_ROOT = process.env.REACT_APP_API_ROOT;
const APP_HOST = process.env.REACT_APP_HOST;

wretch()
  .errorType("json")
  .accept("application/json")
  .content("application/json");

const authClient = {
  signIn(form) {
    return wretch(`${API_ROOT}/api/auth/sign_in`)
      .post(form)
      .unauthorized((error) => {
        return error.json;
      })
      .res((response) => {
        if (response.ok) {
          return response.json().then(({ data }) => {
            const session = {
              "access-token": response.headers.get("access-token"),
              client: response.headers.get("client"),
              uid: response.headers.get("uid"),
            };
            return { success: true, data, session };
          });
        } else {
          return response.json().then((data) => {
            return { success: false, data };
          });
        }
      });
  },

  async signOut() {
    const headers = await getSession();
    return wretch(apiUrl("auth/sign_out"))
      .headers(headers)
      .delete()
      .notFound((error) => {
        return error.json;
      })
      .unauthorized((error) => {
        return error.json;
      })
      .json();
  },

  register(form) {
    return wretch(apiUrl("auth"))
      .post(form)
      .unauthorized((error) => {
        ////console.log("ERROR", error);
        return error.json;
      })
      .res((response) => {
        if (response.ok) {
          return response.json().then(({ data }) => {
            const session = {
              "access-token": response.headers.get("access-token"),
              client: response.headers.get("client"),
              uid: response.headers.get("uid"),
            };
            return { success: true, data, session };
          });
        } else {
          return response.json().then((data) => {
            return { success: false, data };
          });
        }
      });
  },
};

const validateSession = async (body) => {
  const paramsString = Object.entries(body)
    .reduce((params, [key, value]) => {
      return params.concat(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }, [])
    .join("&");

  return wretch(apiUrl(`auth/validate_token?${paramsString}`))
    .get()
    .unauthorized(() => {
      return { success: false };
    })
    .json()
    .catch((error) => {
      honeybadger.notify(error);
      return { success: false };
    });
};

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [initialLoadPerformed, setInitialLoadPerformed] = useState(false);
  useEffect(() => honeybadger.setContext({ user }), [user]);

  useEffect(() => {
    if (!initialLoadPerformed) {
      getSession().then((existingSession) => {
        if (existingSession) {
          validateSession(existingSession).then((response) => {
            ////console.log("existingSession", existingSession, response.data);

            if (response.success) {
              setUser({
                ...response.data,
                user_interests: existingSession.user_interests,
              });
            }

            setInitialLoadPerformed(true);
          });
        } else {
          setInitialLoadPerformed(true);
        }
      });
    }
  }, [initialLoadPerformed]);

  const updateSession = () => {
    getSession().then((existingSession) => {
      if (existingSession) {
        ////console.log("ext");
      }
    });
  };

  const signIn = (form) =>
    authClient.signIn(form).then((response) => {
      if (response.success) {
        validateSession(response.session).then((valResponse) => {
          ////console.log("existingSession", existingSession, response.data);

          setUser({
            ...valResponse.data,
            user_interests: response.data.user_interests,
          });
        });

        const session = {
          ...response.session,
          user_interests: response.data.user_interests,
        };

        setSession(session);
      }

      return response;
    });

  const register = (form) =>
    authClient.register({ app_user: form }).then((response) => {
      if (response.success) {
        setSession(response.session);
        setUser(response.data);
      }

      return response;
    });

  const signOut = () =>
    authClient.signOut().then(() => {
      removeSession();
      setUser(null);
    });

  if (!initialLoadPerformed) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        register,
        setUser,
        updateSession,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function useSession() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a AuthProvider`);
  }
  return getSession();
}

function useUser() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a AuthProvider`);
  }
  return context.user;
}

export { AuthProvider, useAuth, useUser, useSession, getSession, setSession };
