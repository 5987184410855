import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import "./WithBackButton.css";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import useParams from "../utilities/useParams.js";
import { LocationContext } from "../App.js";

const WithBackButton = ({ to, children, toPartners }) => {
	const history = useHistory();
	const [animate, setAnimate] = useState();
	const query = useParams();
	const { previous, setPrevious } = useContext(LocationContext);
	const eventId = query.get("event");

	const animateNavigate = () => {
		setAnimate(true);
		setTimeout(() => {
			if (!to) {
				history.push(previous ? previous : "/events");
				setPrevious(null);
			} else {
				if (to === "/partners") {
					history.push({ pathname: to, search: "event=" + eventId });
				} else {
					history.push({ pathname: to });
				}
			}
		}, 140);
	};

	return (
		<>
			<header className="with-back-button-container">
				<ChevronLeftIcon className="back-icon" onClick={animateNavigate} />
			</header>
			<div className={animate ? "fade" : ""}>{children}</div>
		</>
	);
};
export default WithBackButton;
