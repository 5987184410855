import React, { createContext, useContext, useState } from 'react';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [backgroundColor, setBackgroundColor] = useState("");
  const [highlightColor, setHighlightColor] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [logo, setLogo] = useState("");
  const [picture, setPicture] = useState("");

  const switchTheme = (eventDetails) => {
	setBackgroundColor(eventDetails?.data.background_color || null);
	setHighlightColor(eventDetails?.data.highlight_color || "#65f5e6");
	setBackgroundImage(eventDetails?.data.background_image || null);
	setLogo(eventDetails?.data.logo || null);
	setPicture(eventDetails?.data.picture || null);
  };

  const contextValue = {
	backgroundColor,
	highlightColor,
	backgroundImage,
	logo,
	picture,
	switchTheme,
  };

  return (
	<ThemeContext.Provider value={contextValue}>
	  {children}
	</ThemeContext.Provider>
  );
};

const useTheme = () => {
  return useContext(ThemeContext);
};

export { ThemeProvider, useTheme };
