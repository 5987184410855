import styles from "./TopBar.module.css";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { ReactComponent as Account } from "../icons/account.svg";
import useParams from "../utilities/useParams.js";
import { ReactComponent as ProfessioMatchLogo } from "../icons/match-logo-big.svg";
import { LocationContext } from "../App.js";
import { useContext } from "react";
const TopBar = ({ hideWhenAdmin, user }) => {
	const location = useLocation();
	const isLogin = location.pathname.includes("login");
	const isAdmin = location.pathname.includes("admin");
	const isRegister = location.pathname.includes("register");
	const isOnboarding = location.pathname.includes("onboarding");
	const { signOut } = useAuth();
	const query = useParams();
	const eventId = query.get("event");
	const history = useHistory();
	const { previous, setPrevious } = useContext(LocationContext);

	if (hideWhenAdmin && isAdmin) {
		return null;
	}
	return (
		<>
			{!isOnboarding && (
				<div
					className={`
      ${isAdmin && styles.admin}
      ${styles.topBarContainer}
    }`}>
					{!isLogin && !isAdmin && !isRegister && !isOnboarding && (
						<ProfessioMatchLogo className={styles.topLogo} />
					)}
					{/*user && (
            <p
              className="sign-out-text-top-bar"
              onClick={() => {
                signOut().then(() => {
                  history.push("/login");
                });
              }}
            >
              Kirjaudu <br /> ulos
            </p>
          )*/}
					{user && (
						<Account
							onClick={() => {
								setPrevious({
									pathname: location.pathname,
									search: "event=" + eventId,
								});
								history.push({
									pathname: "/user",
									search: "event=" + eventId,
								});
							}}
							className="nav-item sign-out-text-top-bar"
							style={{ color: "white", opacity: "0.7", marginBottom: 5 }}
						/>
					)}
				</div>
			)}
		</>
	);
};
export default TopBar;
