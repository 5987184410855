import {useLocation, useHistory} from "react-router-dom";
import {useEffect, useState, useContext} from "react";
import {ReactComponent as Logo} from "../icons/match-logo.svg";
import {ReactComponent as ProfessioMatchLogo} from "../icons/match-logo-big.svg";

import {useAuth} from "../contexts/AuthContext";
import NavItem from "../components/NavItem";

import {ReactComponent as Target} from "../icons/target.svg";
import {ReactComponent as Calendar} from "../icons/calendar.svg";
import {ReactComponent as Handshake} from "../icons/handshake.svg";
import {ReactComponent as UserPlus} from "../icons/user-plus.svg";
import {ReactComponent as Account} from "../icons/account.svg";
import {ReactComponent as Video} from "../icons/video.svg";
import {ReactComponent as Case} from "../icons/briefcase.svg";
import {useTheme} from "../contexts/ThemeContext";
import Button from "../components/Button";
import useParams from "../utilities/useParams.js";
import {useOwnCompanyInfo} from "../helpers/helpers";
import "./Navigation.css";
import {useLanguage} from "../contexts/LanguageContext";
import {useQuery} from "react-query";
import {getEvent} from "../api";

const Navigation = ({hideWhenAdmin, user}) => {
    const {pathname, search} = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
    const history = useHistory();
    const query = useParams();
    const eventId = query.get("event");
    const { switchTheme } = useTheme();
    const location = useLocation();
    const {ownCompanyId, isPartnerUser} =
        useOwnCompanyInfo(user);
    const { translations } = useLanguage();

    const { data: eventDetails } = useQuery(
		["getEvent", { eventId }],
		getEvent,
		{
		  enabled: !!eventId,
		  refetchOnWindowFocus: false,
		}
	);

 
    useEffect(() => {
        const handleResizeEvent = () => {
            if (!isMobile && window.innerWidth < 800) {
                setIsMobile(true);
            } else if (isMobile && window.innerWidth >= 800) {
                setIsMobile(false);
            }
        };

        window.addEventListener("resize", handleResizeEvent);

        return function cleanup() {
            window.removeEventListener("resize", handleResizeEvent);
        };
    }, [isMobile]);

   
  useEffect(() => {
	if (eventDetails) {
	  switchTheme(eventDetails);
	}
  }, [eventDetails]);

    let navHidden =
        pathname === "/" ||
        (pathname === "/events" && isMobile) ||
        pathname === "/login" ||
        pathname === "/forgot-password" ||
        pathname === "/reset-password" ||
        pathname === "/register" ||
        pathname === "/onboarding" ||
        pathname === "/terms-of-service" ||
        pathname.includes("admin");

    if (navHidden && hideWhenAdmin) {
        return null;
    }

	const theme = useTheme();
	const highlightColor = theme?.highlightColor
	const backgroundColor = theme?.backgroundColor
	const backgroundImage = theme?.backgroundImage
    const eventPathNames = ["/home", "/user", "/participants", "/calendar", "/partner", "/partners", "/partners/partner", "/streams"];

    if ((backgroundImage || backgroundColor) && eventId !== "null" && eventPathNames.includes(location.pathname)) {
        if (backgroundImage) {
            document.body.style.backgroundImage = `url(${backgroundImage})`;
        } else {
            document.body.style.backgroundImage = "none";
            document.body.style.backgroundColor = backgroundColor;
        }
    } else {
        document.body.style.backgroundImage = "";
        document.body.style.backgroundImage = 'url("./images/background-4.jpg") !important';
    }

    const disabled = !eventId || eventId === "null";
    const display = hideWhenAdmin ? "inline-block" : "block";

    const isActive = (label) => {
        return pathname === label;
    };

    const style = isMobile
        ? {height: 30, width: 30, fill: "white", position: "relative", bottom: 3}
        : {height: 40, width: 40, fill: "white"};

    return (
        <nav className="bottom-nav z-index-2">
            {theme?.logo ? (
                <img src={theme?.logo} className="logo-container dynamic-logo"/>
            ) : (
                <ProfessioMatchLogo className="professio-match-logo"/>
            )}

            <NavItem
                display={display}
                to={"/home"}
                search={"event=" + eventId}
                label={translations.meetings}
                horizontalCenter={hideWhenAdmin}
                disabled={disabled}
                isActive={isActive("/home")}
                icon={Case}
                labelNoMargin
                color={highlightColor}
            />
            <NavItem
                display={display}
                to={"/participants"}
                search={"event=" + eventId}
                label={translations.participants}
                disabled={disabled}
                horizontalCenter={hideWhenAdmin}
                isActive={isActive("/participants")}
                icon={UserPlus}
                color={highlightColor}
            />

            <NavItem
                display={display}
                to={"/calendar"}
                search={"event=" + eventId}
                label={translations.schedule}
                disabled={disabled}
                horizontalCenter={hideWhenAdmin}
                isActive={isActive("/calendar")}
                icon={Calendar}
                color={highlightColor}
            />

            <NavItem
                display={display}
                to={"/partners"}
                search={"event=" + eventId}
                label={translations.partners}
                disabled={disabled}
                horizontalCenter={hideWhenAdmin}
                isActive={isActive("/partners")}
                icon={Target}
                color={highlightColor}
            />

		  {isPartnerUser && (
			  <NavItem
				display={display}
				to={"/partner"}
				search={"event=" + eventId + "&partner=" + ownCompanyId}
				label={translations.summary}
				disabled={(isPartnerUser && !ownCompanyId)}
				horizontalCenter={hideWhenAdmin}
				isActive={isActive("/partner")}
				icon={Target}
				color={highlightColor}
			  />
		  	)
		  }
            <NavItem
              	display={display}
                to={"/streams"}
                search={"event=" + eventId}
                label={translations.live}
                disabled={disabled}
                horizontalCenter={hideWhenAdmin}
                isActive={isActive("/streams")}
                icon={Video}
                color={highlightColor}
            />

            <Button
                className="all-events-button"
                style={{borderColor: highlightColor}}
                disabled={pathname === "/events"}
                onClick={() => {
                    history.push("/events");
                }}>
              {translations.allEvents}
            </Button>

            <Account
                onClick={() => {
                    history.push({
                        pathname: "/user",
                        search: "event=" + eventId,
                        state: {from: location.pathname},
                    });
                }}
                className="nav-item sign-out-text-navigation"
                style={{color: "white", opacity: "0.7", marginTop: 3}}
            />
        </nav>
    );
};

export default Navigation;
