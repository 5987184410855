import "./HorizontalCenter.css";

const HorizontalCenter = ({ children, className = "", ...rest }) => {
  return (
    <div className={`${className && className} centeredContainer`} {...rest}>
      {children}
    </div>
  );
};

export default HorizontalCenter;
