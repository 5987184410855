import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from "@honeybadger-io/react";
import {QueryClient, QueryClientProvider} from "react-query";
import {LanguageProvider, useLanguage} from "./contexts/LanguageContext";
import {BrowserRouter as Router} from "react-router-dom";

import "./index.css";

import honeybadger from "./honeybadger";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {AuthProvider} from "./contexts/AuthContext";

const queryClient = new QueryClient();

ReactDOM.render(
	<ErrorBoundary honeybadger={honeybadger}>
		<React.StrictMode>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<Router>
						<LanguageProvider>
							<App/>
						</LanguageProvider>
					</Router>
				</AuthProvider>
			</QueryClientProvider>
		</React.StrictMode>
	</ErrorBoundary>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(////console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
