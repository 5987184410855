import styles from "./Button.module.css";

const Button = ({
  large,
  black,
  green = true,
  className,
  children,
  fullWidth,
  disabled,
  ...rest
}) => {
  return (
    <button
      {...rest}
      disabled={disabled}
      className={`
        ${black && !green && styles.basic}
        ${green && disabled ? styles.greenDisabled : styles.green}
        ${fullWidth && "full-width"}
        ${className && className}
        ${styles.button}
      `}
    >
      {children}
    </button>
  );
};
export default Button;
