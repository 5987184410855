import HorizontalCenter from "./HorizontalCenter";
import VerticalCenter from "./VerticalCenter";

import { useLocation, useHistory } from "react-router-dom";
import {useTheme} from "../contexts/ThemeContext";
import "./NavItem.css";
import Loading from "./Loading";
import useParams from "../utilities/useParams";

const NavItem = ({
  icon: Icon,
  to,
  search,
  label = "",
  horizontalCenter,
  disabled,
  isActive,
  style,
  labelNoMargin,
  color,
}) => {
  const history = useHistory();

  return (
    <HorizontalCenter
      className="nav-item pointer"
      onClick={() => {
        if (!disabled) history.push({ pathname: to, search: search });
      }}
    >
        {Icon && (
            <Icon
                className={`nav-icon ${ disabled ? "opacity-05" : "white" }`}
                style={style ? style : { color: isActive ? color : "white" }}
            />
        )}

      <small
          className={`nav-icon ${ disabled ? "opacity-05" : "white"}
           nav-item-label pointer
        `}
          style={style ? style : { color: isActive ? color : "white" }}
      >
        {label}
      </small>
    </HorizontalCenter>
  );
};
export default NavItem;
