import logo from "./logo.svg";
import "./App.css";
import {Suspense, lazy, useEffect, useRef} from "react";
import {
	Switch,
	Route,
	Redirect,
	useHistory,
	useRouteMatch,
} from "react-router-dom";
import Toast from "./components/Toast.js";
import WithBackButton from "./components/WithBackButton";
import TopBar from "./components/TopBar";
import EventNameWrapper from "./components/EventNameWrapper";
import {useUser} from "./contexts/AuthContext";
import Navigation from "./components/Navigation";
import GoogleTagManager from "./components/GoogleTagManager";
import ErrorBoundary from "@honeybadger-io/react";
import honeybadger from "./honeybadger";
import {useState, createContext} from "react";
import {ThemeProvider} from "./contexts/ThemeContext";
import {useLanguage} from "./contexts/LanguageContext";

const Login = lazy(() => import("./views/LoginView.js"));
const Register = lazy(() => import("./views/RegisterView.js"));
const TermsOfService = lazy(() => import("./views/TermsOfService.js"));

const EventParticipants = lazy(() => import("./views/EventParticipants.js"));
const Partners = lazy(() => import("./views/Partners.js"));
const Events = lazy(() => import("./views/Events.js"));
const Onboarding = lazy(() => import("./views/Onboarding.js"));
const Home = lazy(() => import("./views/Home.js"));
const Calendar = lazy(() => import("./views/Calendar.js"));
const ForgotPassword = lazy(() => import("./views/ForgotPassword.js"));
const ResetPassword = lazy(() => import("./views/ResetPassword.js"));
const Partner = lazy(() => import("./views/Partner.js"));
const Recording = lazy(() => import("./views/Recording.js"));
const Streams = lazy(() => import("./views/Streams.js"));
const User = lazy(() => import("./views/User.js"));

export const LocationContext = createContext(null);
const LocationProvider = ({children}) => {
	const [previous, setPrevious] = useState(null);

	return (
		<LocationContext.Provider value={{previous, setPrevious}}>
			{children}
		</LocationContext.Provider>
	);
};

const AuthenticatedRoute = ({children}) => {
};

function App() {
	const user = useUser();
	const refContainer = useRef(null);
	const { translations } = useLanguage();

	const ErrorComponent = () => (
		<h1 className="sans center sans margin-48 margin-top">
			{translations.error}
		</h1>
	);

	/* pre clock side change*/
	return (
		<LocationProvider>
			{/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
			<GoogleTagManager/>
			<ThemeProvider>
				<Suspense
					fallback={
						<>
							<main ref={refContainer}>
								<TopBar hideWhenAdmin user={user}/>
							</main>

							<Navigation hideWhenAdmin user={user}/>
						</>
					}>
					<Switch>
						{user ? (
							<main className={(useRouteMatch("/streams") || useRouteMatch("/recordings")) ? "stream-layout" : null} ref={refContainer}>
								<TopBar hideWhenAdmin user={user}/>
								<Route exact path="/">
									<Redirect to="/events"/>
								</Route>

								<Route exact path="/login">
									<Redirect to="/events"/>
								</Route>

								<Route exact path="/register">
									<Redirect to="/"/>
								</Route>

								<Route path="/events" exact component={Events}/>

								<Route
									path="/user"
									render={() => (
										<WithBackButton>
											<ErrorBoundary
												honeybadger={honeybadger}
												ErrorComponent={ErrorComponent}>
												<User user={user}/>
											</ErrorBoundary>
										</WithBackButton>
									)}
								/>
								<Route
									path="/home"
									exact
									render={() => (
										<WithBackButton>
											<EventNameWrapper>
												<ErrorBoundary
													honeybadger={honeybadger}
													ErrorComponent={ErrorComponent}>
													<Home user={user}/>
												</ErrorBoundary>
											</EventNameWrapper>
										</WithBackButton>
									)}
								/>

								<Route
									path="/onboarding"
									exact
									component={() => (
										<ErrorBoundary
											honeybadger={honeybadger}
											ErrorComponent={ErrorComponent}>
											<Onboarding user={user}/>
										</ErrorBoundary>
									)}
								/>

								<Route
									path="/participants"
									exact
									component={() => (
										<WithBackButton>
											<EventNameWrapper>
												<ErrorBoundary
													honeybadger={honeybadger}
													ErrorComponent={ErrorComponent}>
													<EventParticipants user={user}/>
												</ErrorBoundary>
											</EventNameWrapper>
										</WithBackButton>
									)}
								/>

								<Route
									path="/calendar"
									exact
									component={() => (
										<WithBackButton>
											<EventNameWrapper>
												<ErrorBoundary
													honeybadger={honeybadger}
													ErrorComponent={ErrorComponent}>
													<Calendar user={user}/>
												</ErrorBoundary>
											</EventNameWrapper>
										</WithBackButton>
									)}
								/>

								<Route
									path="/partners"
									exact
									component={() => (
										<WithBackButton>
											<EventNameWrapper>
												<ErrorBoundary
													honeybadger={honeybadger}
													ErrorComponent={ErrorComponent}>
													<Partners user={user}/>
												</ErrorBoundary>
											</EventNameWrapper>
										</WithBackButton>
									)}
								/>
								<Route
									path="/partners/partner"
									exact
									component={() => (
										<WithBackButton to="/partners">
											<EventNameWrapper>
												<ErrorBoundary>
													<Partner user={user}/>
												</ErrorBoundary>
											</EventNameWrapper>
										</WithBackButton>
									)}
								/>

								<Route
									path="/recordings/recording"
									exact
									component={() => (
										<WithBackButton to="/calendar">
											<EventNameWrapper>
												<ErrorBoundary>
													<Recording user={user}/>
												</ErrorBoundary>
											</EventNameWrapper>
										</WithBackButton>
									)}
								/>

								<Route
									path="/partner"
									exact
									component={() => (
										<WithBackButton>
											<EventNameWrapper>
												<ErrorBoundary honeybadger={honeybadger}>
													<Partner user={user}/>
												</ErrorBoundary>
											</EventNameWrapper>
										</WithBackButton>
									)}
								/>

								<Route
									path="/streams"
									exact
									component={() => (
										<WithBackButton>
											<EventNameWrapper>
												<ErrorBoundary honeybadger={honeybadger}>
													<Streams user={user}/>
												</ErrorBoundary>
											</EventNameWrapper>
										</WithBackButton>
									)}
								/>
								<Route
									path="/terms-of-service"
									exact
									render={() => (
										<WithBackButton>
											<EventNameWrapper>
												<ErrorBoundary
													honeybadger={honeybadger}
													ErrorComponent={ErrorComponent}>
													<TermsOfService/>
												</ErrorBoundary>
											</EventNameWrapper>
										</WithBackButton>
									)}
								/>
							</main>
						) : (
							<>
								<Route exact path="/">
									<Redirect to="/login"/>
								</Route>
								<Route path="/events">
									<Redirect to="/login"/>
								</Route>
								<Route path="/calendar">
									<Redirect to="/login"/>
								</Route>
								<Route path="/participants">
									<Redirect to="/login"/>
								</Route>
								<Route path="/streams">
									<Redirect to="/login"/>
								</Route>
								<Route path="/user">
									<Redirect to="/login"/>
								</Route>

								<Route
									path="/forgot-password"
									component={() => (
										<WithBackButton to="/login">
											<ForgotPassword/>
										</WithBackButton>
									)}
								/>
								<Route
									exact
									path="/reset-password"
									component={() => <ResetPassword/>}
								/>
								<Route path="/login" exact component={Login}/>
								<Route path="/register" exact component={Register}/>
								<Route
									path="/terms-of-service"
									exact
									render={() => (
										<WithBackButton>
											<EventNameWrapper>
												<ErrorBoundary
													honeybadger={honeybadger}
													ErrorComponent={ErrorComponent}>
													<TermsOfService/>
												</ErrorBoundary>
											</EventNameWrapper>
										</WithBackButton>
									)}
								/>
								<Route
									path="/www.professio.fi"
									component={() => {
										window.location.href = "https://www.professio.fi";
										return null;
									}}
								/>
							</>
						)}
					</Switch>
					<Navigation hideWhenAdmin user={user}/>
					<Toast/>
				</Suspense>
			</ThemeProvider>
		</LocationProvider>
	);
}

const NoMatch = () => {
	const { translations } = useLanguage();
	return <h1 className="center mono">{translations.pageNotFound}</h1>;
};

export default App;