import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import useParams from "../utilities/useParams.js";

const GoogleTagManager = () => {
	const { pathname: page_path } = useLocation();
	const params = useParams();

	useEffect(() => {
		reportEvent({
			event: "view_page",
			page_path: page_path.split("com/")[1],
		});
	}, [page_path]);

	return null;
};
export default GoogleTagManager;

export const REACT_APP_MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;

export const reportEvent = (eventToReport) => {
	//const cookiesAccepted = cookies.get("user-has-accepted-cookies") === "true";
	window.dataLayer.push(eventToReport);
};
