import useParams from "../utilities/useParams";
import "./EventNameWrapper.css";
import { useQuery } from "react-query";
import { getEvents } from "../api";

const EventNameWrapper = ({ children }) => {
  const params = useParams();
  const eventId = params.get("event");
  const { data: eventsData, isLoading } = useQuery("events", getEvents);

  const selected = eventsData?.data.find((event) => event.id == eventId);

  return (
    <>
      <div className="app-padding margin-24 margin-bottom">
        <h2 id="event-title">{selected?.title}</h2>
      </div>
      {children}
    </>
  );
};
export default EventNameWrapper;
