import Honeybadger from "@honeybadger-io/js";

const config = {
  apiKey: "hbp_GnwWVOyhwbef9ojYhrSrFflipvpozt26faGb",
  environment: process.env.REACT_APP_ENVIRONMENT || "development",
};

const honeybadger = Honeybadger.configure(config);

export default honeybadger;
