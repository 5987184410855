import PulseLoader from "react-spinners/PulseLoader";
import HorizontalCenter from "./HorizontalCenter";

import { css } from "@emotion/react";
import "./Loading.css";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  color: red;
  border-radius: 0px;
`;

const Loading = () => (
  <HorizontalCenter>
    <div className="loadingio-spinner-bars-h089f3sylmg">
      <div className="ldio-ovs4x40r7d" style={{ display: "flex" }}>
        <div style={{ flex: 1 }}></div>
        <div style={{ flex: 1 }}></div>
        <div style={{ flex: 1 }}></div>
      </div>
    </div>
  </HorizontalCenter>
);
export default Loading;
