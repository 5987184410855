import {
  format,
  compareAsc,
  parseISO,
  isBefore,
  isAfter,
  isToday,
} from "date-fns";
import useParams from "../utilities/useParams.js";
import {
  getEventCompanies,
  getEventPartner,
  getEventPartners,
  getTimeslots,
  getEventParticipant,
  getEvents,
} from "../api";
import { useQuery } from "react-query";
import { useUser } from "../contexts/AuthContext";
import {useLanguage} from "../contexts/LanguageContext";

export const getParticipantLocation = (user) => {
  /*
	Etäosallistuja (free), Lähiosallistuja (free), Osallistuja (FREE),  
	Lähiosallistuja, Osallistuja, Osallistuja (Free), Attendant.  -
	 Everyone in this category should be visible as "Lähiosallistuja" or  
	 "Etäosallistuja" in Match front-end.  Attendees visible as "Lähiosallistuja" in 
	 Professio Match should be: Lähiosallistuja (free), Osallistuja (FREE),  
	 Lähiosallistuja, Osallistuja, Osallistuja (Free), and Attendant. 
	 Attendees visible as "Etäosallistuja" should be: Etäosallistuja (free), and Etäosallistuja.

	*/
  const remoteRoles = [
    "Etäosallistuja (free)",
    "Etäosallistuja",
    "Free - stream included",
  ];

  if (user?.participation_type) {
    return user?.participation_type === "etäosallistuminen";
  } else {
    return remoteRoles.includes(user?.role);
  }
};

export const getWeekDay = (time) => {
  const { translations } = useLanguage();
  let weekDay;
  switch (format(new Date(time), "EEEEEE")) {
    case "Mo":
      weekDay = translations.mo;
      break;
    case "Tu":
      weekDay = translations.tu;
      break;
    case "We":
      weekDay = translations.we;
      break;
    case "Th":
      weekDay = translations.th;
      break;
    case "Fr":
      weekDay = translations.fr;
      break;
    case "Sa":
      weekDay = translations.sa;
      break;
    case "Su":
      weekDay = translations.su;
      break;
    default:
  }
  return weekDay;
};

export const getFullWeekDay = (time) => {
  const { translations } = useLanguage();
  let weekDay;
  switch (format(new Date(time), "EEEEEE")) {
    case "Mo":
      weekDay = translations.mon;
      break;
    case "Tu":
      weekDay = translations.tue;
      break;
    case "We":
      weekDay = translations.wed;
      break;
    case "Th":
      weekDay = translations.thu;
      break;
    case "Fr":
      weekDay = translations.fri;
      break;
    case "Sa":
      weekDay = translations.sat;
      break;
    case "Su":
      weekDay = translations.sun;
      break;
    default:
  }
  return weekDay;
};

export const getTime = (date) => {
  var d = new Date(date);
  return format(d, "HH:mm");
};

export const dateIsToday = (date) => {
  var d = new Date(date);
  return isToday(d);
};

export const getFormattedTodayDate = () => {
  const date = new Date();
  return format(date, "yyyy-MM-dd");
};

export const getEventIsNow = (starts, ends) => {
  const d = new Date();
  const startTime = new Date(starts);
  const endTime = new Date(ends);

  const nowIsBefore = isBefore(d, endTime);
  const nowIsAfter = isAfter(d, startTime);

  return nowIsBefore && nowIsAfter;
};

export const getDate = (date) => {
  var d = new Date(date);
  return format(d, "d.M");
};

export const getDate2 = (date) => {
  return format(date, "dd.M");
};

export const getTimeIsNow = () => {
  return true;
};

export const useEventParticipant = (passedUser) => {
  const user = passedUser || useUser();
  const query = useParams();
  const eventId = query.get("event");

  let { data: eventParticipant } = useQuery(
    ["getEventParticipant", { eventId, userId: user?.id }],
    getEventParticipant,
    {
      enabled: !!user && !!eventId,
      refetchOnWindowFocus: false,
    }
  );

  const streamsHidden = eventParticipant?.data.streams_hidden

  return {
    eventParticipant,
    streamsHidden,
    userIsPartnerAdmin: eventParticipant?.data.meeting_organizer, //means user is type partner and can make meeting requests on behalf of colleagues
  };
};

export const useEventEnabledForFreeUsers = (eventId) => {
  const { data: eventsData, isLoading } = useQuery("events", getEvents, {
    refetchOnWindowFocus: false,
  });

  return eventsData?.data.find((ev) => ev.id == eventId).free_user_meetings;
};

export const useOwnCompanyInfo = (passedUser) => {
  const user = passedUser || useUser();
  const query = useParams();
  const eventId = query.get("event");

  const { eventParticipant, streamsHidden, userIsPartnerAdmin } =
    useEventParticipant(passedUser);

  const isPartnerUser =
    eventParticipant?.data.role &&
    (eventParticipant?.data.role === "PS (Partner)" ||
      eventParticipant?.data.role === "Partner" ||
      eventParticipant?.data.role === "Staff");

  const { data: companies } = useQuery(
    ["getEventCompanies", { eventId }],
    getEventCompanies,
    {
      enabled: !!eventId && isPartnerUser && !!user,
      refetchOnWindowFocus: false,
    }
  );

  let ownCompanyId;
  let ownCompany;
  if (companies && user && isPartnerUser) {
    ownCompany = companies?.data.find((company) =>
      company.user_ids.some((id) => id === user?.id)
    );
    ownCompanyId = ownCompany?.id;
  }

  const enabled = isPartnerUser && !!ownCompanyId && !!user;

  const { data: partner } = useQuery(
    ["getPartner" + ownCompanyId, { eventId, companyId: ownCompanyId }],
    getEventPartner,
    {
      enabled: !!ownCompanyId && !!eventId,
      refetchOnWindowFocus: false,
    }
  );

  const meetingsCapped =
    partner &&
    partner.data.amount_of_meeting_request -
      partner.data.requested_meetings_count <
      1;
  //partner can no longer send more meeting requests

  const { data: partners } = useQuery(
    ["getPartnerFavourites", { eventId }],
    getEventPartners,
    {
      enabled: !!isPartnerUser && !!eventId && eventId !== "null" && !!user,
      refetchOnWindowFocus: false,
    }
  );

  const companyUsers = partners?.data.filter(partner =>
  partner.companies.some(company => company.id === ownCompanyId));

  let companyFavourites = new Set();

  if (companyUsers) {
    companyUsers.forEach((user) => {
      user.company_favourite_ids.forEach(id => {
        companyFavourites.add(id)
      })
    });
  }

  companyFavourites = Array.from(companyFavourites) || [];

  let ownColleagueIds = partner?.data.users.map((user) => user.id);

  const ownColleagues =
    ownCompany &&
    partners?.data.filter(
      (partner) =>
        ownCompany.user_ids.includes(partner.user_id) &&
        partner.user_id !== eventParticipant?.data.id
    );

  return {
    ownCompanyId,
    ownColleagueIds,
    ownColleagues,
    companyFavourites,
    hasLunchMeetings: partner?.data.lunch,
    isPartnerUser,
    streamsHidden,
    currentUser: eventParticipant?.data,
    userIsPartnerAdmin,
    meetingsCapped,
  };
};

export const useOneToManyMeetings = (startTime) => {
  const query = useParams();
  const eventId = query.get("event");

  const { data: eventTimeslots } = useQuery(
    ["eventTimeslots", { eventId }],
    getTimeslots,
    {
      refetchOnWindowFocus: false,
    }
  );

  const timeslots = eventTimeslots?.data.filter(
    (t) => t.category === "lunch" || t.category === "breakfast"
  );

  if (startTime && timeslots) {
    const timeslot = timeslots.find((t) => t.starts_at === startTime);

    return { timeslot, timeslots };
  } else {
    return { timeslot: null, timeslots };
  }
};

export function isHTMLStringValid(htmlString) {
  // Check if the HTML string contains any HTML tags
  const hasHTMLTags = /<\/?[a-z][\s\S]*>/i.test(htmlString);

  // If it contains HTML tags, perform the validation
  if (hasHTMLTags) {
    // Create a temporary div element to parse the HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    // Find all anchor tags in the parsed HTML
    const anchorTags = tempDiv.querySelectorAll("a");

    // Loop through each anchor tag and validate it
    for (const anchorTag of anchorTags) {
      // Validate target attribute
      const target = anchorTag.getAttribute("target");
      if (target !== "_blank") {
        return false;
      }

      // Validate href attribute
      const href = anchorTag.getAttribute("href");
      const urlPattern = /^https:\/\/.+/i; // Match any HTTPS URL
      if (!urlPattern.test(href)) {
        return false;
      }
    }
  }

  // If it doesn't contain HTML tags or all anchor tags are valid, return true
  return true;
}
