import React, { createContext, useContext, useState, useEffect } from 'react';
import useParams from "../utilities/useParams.js";
import {useQuery} from "react-query";
import {getEvent} from "../api";

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
	const storedLanguage = localStorage.getItem('userLanguage');
	const query = useParams();
    const eventId = query.get("event");

	const [language, setLanguage] = useState(storedLanguage || 'en');
	const [translations, setTranslations] = useState({});


	const { data: eventDetails } = useQuery(
		["getEvent", { eventId }],
		getEvent,
		{
		  enabled: !!eventId,
		  refetchOnWindowFocus: false,
		}
	);

	const loadTranslations = async (newLanguage) => {
		try {
			const response = await fetch(`/locales/${newLanguage}.json`);
			const translationsData = await response.json();
			setTranslations(translationsData);
		} catch (error) {
			console.error('Error loading translations:', error);
		}
	};

	const switchLanguage = (newLanguage) => {
		setLanguage(newLanguage);
		loadTranslations(newLanguage);
		localStorage.setItem('userLanguage', newLanguage);
	};


	useEffect(() => {	
		const storedLanguage = localStorage.getItem('userLanguage');
		
		if(eventDetails){ // event selected
			const eventLanguage = eventDetails?.data.language;

			if(!storedLanguage && !eventLanguage){
				switchLanguage("en")
			}
			else if(!storedLanguage && eventLanguage){
				switchLanguage(eventLanguage)
			}
			else if(storedLanguage){
				switchLanguage(storedLanguage)
			}
		} else { //no event selected
		  if(storedLanguage){
			switchLanguage(storedLanguage)
		  } else {
			switchLanguage("en")
		  }
		}
		
	}, [language, eventDetails]);

	
	if (!translations) {
		return null
	}

	return (
		<LanguageContext.Provider value={{ language, switchLanguage, translations }}>
			{children}
		</LanguageContext.Provider>
	);
};

const useLanguage = () => {
	return useContext(LanguageContext);
};

export { LanguageProvider, useLanguage };
